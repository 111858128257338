/* eslint-disable prefer-arrow-callback */
import React, { useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  actions,
  editThirdPartyCamera,
  getThirdPartyCamera,
} from 'features/Cameras/camerasSlice'
import { editDevice } from 'features/Devices/devicesSlice'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  FormModal,
  FormModalActions,
  FormModalContent,
  FormModalTitle,
  Input,
} from 'sputnik-ui'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  alias: Yup.string().nullable().max(60),
  rtsp_link: Yup.string()
    .required()
    .matches(
      /^(rtsp):\/\/([^\s:@]+):?([^@\s]*)?@?([^:\s]+)(?::(\d+))?(\/[^\s]*)?$/
    ),
})

export const EditThirdPartyCameras = memo(function EditThirdPartyCameras({
  open,
  onClose,
  uuid: cameraId,
}) {
  const { t } = useTranslation(['devices', 'common'])
  const companyUuid = useSelector(({ auth }) => auth.user?.company?.uuid)
  const { current, pending } = useSelector(({ camerasNew }) => camerasNew)
  const dispatch = useDispatch()

  const { values, handleChange, handleSubmit, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      uuid: cameraId,
      company_uuid: companyUuid || '',
      rtsp_link: current?.rtsp_link || '',
      alias: current?.alias || '',
    },
    validationSchema: schema,
    onSubmit: (val) => {
      dispatch(editThirdPartyCamera(val)).then(() => {
        onClose()
        dispatch(
          editDevice({ deviceUuid: val.uuid, fields: { alias: val.alias } })
        )
      })
    },
  })

  useEffect(() => {
    if (cameraId) {
      dispatch(getThirdPartyCamera(cameraId))
    }
    return () => dispatch(actions.clearCamerasStore())
  }, [cameraId, dispatch])

  return (
    <>
      <FormModal open={open}>
        <FormModalTitle>{t('devices:cameras.edit')}</FormModalTitle>
        <FormModalContent label={t('devices:cameras.already_have')}>
          <Input
            required
            name="rtsp_link"
            label={t('devices:cameras.third_party.rtsp')}
            onChange={handleChange}
            value={values.rtsp_link}
            error={errors.rtsp_link}
            fullWidth
            style={{ marginBottom: 24 }}
            disabled={pending}
          />
          <Input
            name="alias"
            value={values.alias}
            error={errors.alias}
            onChange={handleChange}
            label={t('devices:cameras.third_party.alias')}
            fullWidth
            disabled={pending}
          />
        </FormModalContent>
        <FormModalActions>
          <Button
            variant="v2"
            primary
            fullWidth
            type="submit"
            onClick={handleSubmit}
            disabled={
              Object.keys(errors).length > 0 ||
              pending ||
              !values.alias ||
              !values.rtsp_link
            }
          >
            {t('common:button.save_edit')}
          </Button>
          <Button fullWidth variant="v2" tertiary onClick={onClose}>
            {t('common:button.back')}
          </Button>
        </FormModalActions>
      </FormModal>
    </>
  )
})
