import React, { useEffect, useState } from 'react'
import MainTemplate from 'components/templates/MainTemplate'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearWarrantyCurrent,
  fetchCurrentWarranty,
} from 'features/Warranty/warrantySlice'
import { ReactComponent as IntercomIcon } from 'assets/intercom.svg'
import { ReactComponent as CameraIcon } from 'assets/WhiteCamera.svg'
import styled from 'styled-components'
import { Container, Loader, formatDeviceId } from 'sputnik-ui'
import { useTranslation } from 'react-i18next'
import { getDeviceTypeByNumber } from 'utils/helpers'
import { IconWrapper } from 'shared/ui'
import useWarrantyInputsData from './useWarrantyInputsData'
import warrantyGetColor from './warrantyGetColor'

const Title = styled.div`
  /* font-size: 20px; */
  font-weight: 600;
  margin-bottom: 1rem;
`

const BreakType = styled.div`
  width: fit-content;
  padding: 12px 24px;
  border: 1px solid #dbdade;
`

const BreakList = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
  flex-wrap: wrap;
`

const BreakTitle = styled.div`
  color: ${(p) => p.theme.palette.text.secondary};
  &:first-letter {
    text-transform: uppercase;
  }
`

const BaseInfoWrapper = styled.div`
  width: 100%;
  background-color: #f3f3f4;
`

const BaseInfoContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  padding: 0.7rem 1.625rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const StatusBar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const StatusCircle = styled(IconWrapper)`
  background-color: ${(p) => p.status};
  margin-right: 1.5rem;
`

const SubInfo = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.text.secondary};
  &:first-letter {
    text-transform: uppercase;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`
const Section = styled.div``

function WarrantyCurrent({ number }) {
  const [troubles, setTroubles] = useState([])
  const { t } = useTranslation(['warranty', 'devices', 'orders'])
  const { deviceTroubles } = useWarrantyInputsData()
  const dispatch = useDispatch()
  const currentWarranty = useSelector(({ warranty }) => warranty.current)
  const pending = useSelector(({ warranty }) => warranty.pending)

  useEffect(() => {
    dispatch(fetchCurrentWarranty({ number }))
    return () => {
      dispatch(clearWarrantyCurrent())
    }
  }, [dispatch, number])

  const deviceType = getDeviceTypeByNumber(currentWarranty?.device_number)
  useEffect(() => {
    if (currentWarranty.troubles) {
      const locale_troubles = { ...currentWarranty.troubles }
      const data = []
      deviceTroubles[deviceType].forEach((item) => {
        const { label } = item
        const options = item.options.filter((option) =>
          locale_troubles[item.localId]?.includes(option.errNum)
        )
        if (options.length) data.push({ label, options })
      })
      setTroubles(data)
    }
  }, [currentWarranty.troubles, deviceTroubles, deviceType])

  if (pending || !currentWarranty?.number) return <Loader fullPage />

  return (
    <div style={{ width: '100%', marginTop: '2rem' }}>
      <BaseInfoWrapper>
        <Wrapper>
          <BaseInfoContainer>
            <Row>
              <StatusCircle status={warrantyGetColor(currentWarranty.status)}>
                {deviceType === 'camera' ? <CameraIcon /> : <IntercomIcon />}
              </StatusCircle>
              <StatusBar>
                <BreakTitle>{currentWarranty.locale_status}</BreakTitle>
                <SubInfo>
                  {formatDeviceId(currentWarranty.device_number)}
                </SubInfo>
              </StatusBar>
            </Row>
            <StatusBar style={{ alignItems: 'flex-end' }}>
              <BreakTitle>{t(`devices:units.${deviceType}`)}</BreakTitle>
              <SubInfo>
                {t('orders:units.order_0')} #{currentWarranty.number}
              </SubInfo>
            </StatusBar>
          </BaseInfoContainer>
        </Wrapper>
      </BaseInfoWrapper>
      <MainTemplate>
        <Title style={{ marginBottom: '2rem' }}>
          {t('warranty:title.info')}
        </Title>
        {troubles.map((trouble) => (
          <Section key={trouble.label}>
            <BreakTitle style={{ marginBottom: '1rem' }}>
              {trouble.label}
            </BreakTitle>
            <BreakList>
              {trouble.options.map((option) => (
                <BreakType key={option.errNum}>{option.label}</BreakType>
              ))}
            </BreakList>
          </Section>
        ))}

        <Section>
          <Title>{t('warranty:title.comment')}</Title>
          <div>{currentWarranty.add_info}</div>
        </Section>
      </MainTemplate>
    </div>
  )
}

export default WarrantyCurrent
