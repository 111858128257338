import { createAsyncThunk } from '@reduxjs/toolkit'
import config from 'config'
import { showInfoMessage } from 'store/ui/actions'
import api from 'utils/api'
import { buildUrl } from 'utils/helpers'

export const fetchAllAddresses = createAsyncThunk(
  'devicesVideos/uuids',
  async (
    { deviceType, addressType, search, isOnline, order, sortBy, page, limit },
    { rejectWithValue, dispatch, signal }
  ) => {
    const params = {
      device_type: deviceType || undefined,
      address_type: addressType,
      search: search?.trim() || '',
      is_online: isOnline,
      order,
      sort_by: sortBy,
      page,
      limit,
    }
    try {
      const basePayload = await api.request(
        buildUrl(`/devices`, params),
        { signal },
        config.baseUri
      )

      const payload = {
        meta: basePayload.meta,
        addresses: basePayload.devices.map((el) => el.address_uuid),
      }
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      return rejectWithValue(error)
    }
  }
)
