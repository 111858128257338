import React from 'react'
import styled from 'styled-components'
import { FiltersList } from './FiltersList/FiltersList'
import { VideoList } from './VideoList/VideoList'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const DevicesVideosList = () => (
  <Wrapper>
    <FiltersList />
    <VideoList />
  </Wrapper>
)
