import React, { useState } from 'react'
import { Loader, Mascotte, Wrapper, Radio } from 'sputnik-ui'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import Tooltip from '@material-ui/core/Tooltip'

import { withStyles } from '@material-ui/core/styles'

import { Help } from '@material-ui/icons'
import { ReactComponent as ISee } from 'assets/ISee.svg'
import CameraModal from '../../organisms/CameraModal'
import Camera from '../../atoms/Camera'
import FoldButton from '../../atoms/FoldButton'

const StyledHeader = styled(CardHeader)`
  && {
    /* background-color: ${(p) =>
      p.camerasPresent
        ? p.theme.palette.background.primary
        : p.theme.palette.primary[500]};
    color: ${(p) =>
      p.camerasPresent
        ? p.theme.palette.text.primary
        : p.theme.palette.primary.contrastText}; */
    background-color: ${(p) => p.theme.palette.background.primary};
    color: ${(p) => p.theme.palette.text.primary};
    position: sticky;
    z-index: 2;
    transition: all 200ms ease-in;
    top: 0;
    height: 80px;
  }
`

const StyledContent = styled(CardContent)`
  && {
    transition: max-height 150ms ease-in;
    max-height: 0rem;
    padding: 0 24px;
    overflow-y: auto;
    padding: 0;
    &:last-child {
      padding-bottom: 0;
    }
    &::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(192, 192, 192);
    }

    ${(p) =>
      p.isExpanded &&
      css`
        max-height: 26rem !important;
        /* padding: 16px 24px !important; */
        &:last-child {
          padding-bottom: 1rem;
        }
      `}
  }
`

const styles = (theme) => ({
  heading: {
    color: theme.palette.primary.contrastText,
  },
  cardAction: {
    marginTop: 0,
    marginRight: 0,
    alignSelf: 'center',
  },
  list: {
    padding: 0,
  },
  listItem: {},
  foldButton: {
    zIndex: 2,
    position: 'absolute',
    bottom: '-24px',
    left: 'calc(50% - 24px)',
    right: 0,
    color: theme.palette.primary.main,
    background: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.main,
      background: theme.palette.primary.contrastText,
    },
  },
  arrowIcon: {
    transition: 'all 300ms ease-in',
  },
  arrowRotated: {
    transform: 'rotate(180deg)',
  },
})

const StyledWrapper = styled(Wrapper)`
  padding: 0;
  flex-direction: column;
  overflow: hidden;
  background: ${(p) => p.theme.palette.background.primary};
`

function Cameras({ classes, selectedToArchive, setSelectedToArchive }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpansion = () => setIsExpanded(!isExpanded)

  const [cameraModalOpen, setCameraModalOpen] = useState(false)
  const [currentCamera, setCurrentCamera] = useState(null)

  const openModal = (camera) => {
    setCameraModalOpen(true)
    setCurrentCamera(camera)
  }
  const closeModal = () => {
    setCameraModalOpen(false)
    setCurrentCamera(null)
  }

  const { t } = useTranslation('devices')

  const cameras = useSelector(({ devicesNew }) => devicesNew.cameras)

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <StyledWrapper shadow={false}>
        <StyledHeader
          camerasPresent={cameras && cameras.length > 0}
          avatar={<ISee />}
          title={
            <Typography
              color="inherit"
              style={{
                fontSize: '1.2rem',
              }}
            >
              {cameras && cameras.length < 1
                ? t('devices:cameras.missing')
                : t('devices:cameras.header')}
            </Typography>
          }
          classes={{
            action: classes.cardAction,
          }}
          action={
            <Tooltip title={t('devices:cameras.helper')} placement="top">
              <Help style={{ alignSelf: 'center', cursor: 'help' }} />
            </Tooltip>
          }
        />
        <StyledContent isExpanded={isExpanded}>
          <List>
            {cameras ? (
              cameras.length > 0 ? (
                cameras.map((camera) => (
                  <div
                    key={camera.uuid}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Camera
                      onClick={() => openModal(camera)}
                      key={camera.uuid}
                      {...camera}
                    />
                    <Radio
                      color="blue"
                      checked={selectedToArchive === camera.uuid}
                      onClick={() => setSelectedToArchive(camera.uuid, true)}
                    />
                  </div>
                ))
              ) : (
                <>
                  <Mascotte
                    style={{ maxHeight: 80, marginBottom: '1rem' }}
                    emote="notFound"
                  />
                  <Typography align="center">
                    {t('devices:cameras.missing')}
                  </Typography>
                </>
              )
            ) : (
              <Loader msg={t('devices:cameras.loading')} />
            )}
          </List>
        </StyledContent>
      </StyledWrapper>

      <CameraModal
        open={cameraModalOpen}
        handleClose={closeModal}
        uuid={currentCamera?.uuid}
      />
      {cameras && cameras.length > 0 && (
        <FoldButton isExpanded={isExpanded} onClick={toggleExpansion} />
      )}
    </div>
  )
}

export default withStyles(styles)(Cameras)
