import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchDevices } from 'features/Devices/devicesSlice'
import config from 'config'
import api from 'utils/api'
import { showInfoMessage } from 'store/ui/actions'
import { buildUrl } from 'utils/helpers'

export const fetchWarranty = createAsyncThunk(
  'warranty/fetchWarrantyList',
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const payload = api.request('/guarantee_requests')
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const fetchCurrentWarranty = createAsyncThunk(
  'warranty/fetchCurrentWarranty',
  async ({ number }, { rejectWithValue, dispatch }) => {
    try {
      let type = 'intercom'
      dispatch(fetchDevices({ search: number }))
        .then((el) => {
          type = el?.payload?.devices[0]?.type || 'intercom'
          return el
        })
        .catch(() => {
          dispatch(showInfoMessage('Error'))
        })

      const payload = await api.request(`/guarantee_requests/${number}`)
      return { ...payload, type }
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const checkWarrantyAvailable = createAsyncThunk(
  'warranty/checkWarrantyAvailable',
  async ({ hex }, { rejectWithValue, dispatch }) => {
    try {
      const device = await dispatch(fetchDevices({ search: hex }))
        .then((devicesList) => {
          const currentDevice = devicesList.payload.devices.find(
            (device) => device.serial_number.toString() === hex
          )
          return currentDevice || Promise.reject()
        })
        .catch((err) => {
          dispatch(showInfoMessage('Device not found'))
          throw new Error('Device not found')
        })

      const payload = await api.request(
        buildUrl(`/devices/${device?.uuid}/check_guarantee`, {
          device_kind: device?.type,
        }),
        {},
        `${config.redirectUri}/v2`
      )

      return !!payload.active_guarantee
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const createWarranty = createAsyncThunk(
  'warranty/createWarranty',
  async ({ values, troubles }, { rejectWithValue, dispatch }) => {
    try {
      const uuid = await dispatch(
        fetchDevices({ search: values.device_number })
      )
        .then((devicesList) => {
          const deviceId = devicesList.payload.devices.find(
            (device) =>
              device.serial_number.toString() ===
              values.device_number.toString()
          )?.uuid
          return deviceId || Promise.reject()
        })
        .catch((err) => {
          dispatch(showInfoMessage('Device not found'))
          throw new Error('Device not found')
        })

      const payload = await api.request(
        '/guarantee_requests',
        {
          method: 'POST',
          body: JSON.stringify({
            ...values,
            device_uuid: uuid,
            troubles,
          }),
        },
        config.buildUrl
      )
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

const initialState = {
  successOrder: false,
  total: null,
  list: null,
  current: {},
  pending: false,
  createPending: false,
}

// reducers
export const warrantySlice = createSlice({
  name: 'warranty',
  initialState,
  reducers: {
    clearWarranty: () => initialState,
    closeSuccessOrderModal: (state) => {
      state.successOrder = false
    },
    clearWarrantyCurrent: (state) => {
      state.current = {}
    },
  },
  extraReducers: {
    [fetchWarranty.pending]: (state) => {
      state.pending = true
    },
    [fetchWarranty.fulfilled]: (state, { payload }) => {
      state.pending = false
      state.list = payload.result
      state.total = payload.total
    },
    [fetchWarranty.rejected]: (state) => {
      state.pending = false
    },

    [fetchCurrentWarranty.pending]: (state) => {
      state.pending = true
    },
    [fetchCurrentWarranty.fulfilled]: (state, { payload }) => {
      state.pending = false
      state.current = payload
    },
    [fetchCurrentWarranty.rejected]: (state) => {
      state.pending = false
    },

    [createWarranty.pending]: (state) => {
      state.createPending = true
    },
    [createWarranty.fulfilled]: (state) => {
      state.createPending = false
      state.successOrder = true
    },
    [createWarranty.rejected]: (state) => {
      state.createPending = false
    },
  },
})

export const { clearWarranty, clearWarrantyCurrent, closeSuccessOrderModal } =
  warrantySlice.actions

export default warrantySlice.reducer
