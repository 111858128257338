import React, { useEffect } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'
import Progress from '@material-ui/core/CircularProgress'

import { Typography, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useDispatch, useSelector } from 'react-redux'
import { fetchCamera, fixStreamCamera } from 'features/Cameras/camerasSlice'
import { Button } from 'sputnik-ui'
import { useTranslation } from 'react-i18next'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

function CameraModal({ uuid, open, handleClose, ...props }) {
  const dispatch = useDispatch()
  const camera = useSelector(({ camerasNew }) => camerasNew.current)
  const { t } = useTranslation(['devices'])

  useEffect(() => {
    if (uuid) dispatch(fetchCamera(uuid))
  }, [dispatch, uuid])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      PaperProps={{ style: styles.dialog }}
    >
      <DialogTitle style={{ position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '1rem',
          }}
        >
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
          {camera && camera.serial_number}
          {camera && camera.embed && (
            <Button
              secondary
              onClick={() => dispatch(fixStreamCamera(camera.uuid))}
            >
              {t('devices:params.stream_sync')}
            </Button>
          )}
        </div>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        {camera ? (
          camera.embed ? (
            <div style={styles.responsiveContainer}>
              <iframe
                title="camera-stream"
                src={camera.embed}
                frameBorder="0"
                style={styles.responsiveElement}
                autoPlay={false}
              />
            </div>
          ) : (
            <Typography color="error" align="center">
              {t('devices:cameras.not_available')}
            </Typography>
          )
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '100px 0',
            }}
          >
            <Progress />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

const styles = {
  icon: {
    marginRight: 8,
    width: '1rem',
    height: '1rem',
  },
  dialog: {
    // overflow: 'visible',
    width: '80%',
    maxWidth: 800,
  },
  responsiveContainer: {
    height: 0,
    paddingTop: '56.25%',
    position: 'relative',
    overflow: 'hidden',
    // borderRadius: 5,
    boxShadow: '0px 0px 30px rgba(0,0,0,0.1)',
  },
  responsiveElement: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgb(250, 251, 250)',
  },
}

export default CameraModal
