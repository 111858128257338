import React from 'react'
import styled from 'styled-components'
import { Container, Wrapper } from 'sputnik-ui'
import { Typography } from '@material-ui/core'
import SyncStatus from 'components/atoms/SyncStatus'
import { useTranslation } from 'react-i18next'
import { ShutdownFeature } from 'shared/lib/ShutdownFeature'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background: ${(p) => p.theme.palette.grayscale[2]}; */
  background: ${(p) => p.theme.palette.background.primary};
  padding-bottom: 2rem;
  min-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  box-shadow: inset 0px 0px 100px
    ${(p) =>
      p.isOnline ? p.theme.palette.other.green : p.theme.palette.other.pink};
`

const HeaderWrapper = styled.header``

const Page = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 465px;
  grid-gap: 1rem;
  grid-template-rows: max-content;
  height: auto;

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    grid-template-columns: 1fr;
  }
`

const Settings = styled.div`
  /* background: ${(p) => p.theme.palette.background.primary}; */
  /* min-height: 400px; */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  z-index: 3;
  position: relative;
`

const Info = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  height: auto;
  padding-top: 1.325rem;
  width: 100%;

  flex-wrap: wrap;

  & > div {
    width: auto;
    margin-right: 1rem;
  }
`

const styles = {
  cameraContainer: {
    height: 0,
    paddingTop: '56.25%',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 5,
    boxShadow: '0px 10px 30px rgba(0,0,0,0.2)',
    // maxWidth: '50%',
    background: '#fff',
  },
  camera: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgb(255, 255, 255)',
  },
  sectionTitle: {
    marginBottom: '2rem',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}

const CameraContainer = styled.div`
  height: 0;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  border-radius: ${(p) => p.theme.sizes.borderRadius};
  will-change: box-shadow;
  transition: box-shadow 300ms ease-in;
  /* box-shadow: 0px 20px 50px
    ${(p) =>
    p.isOnline ? p.theme.palette.other.green : p.theme.palette.other.pink}; */
  /* max-width: 50%; */
  background: ${(p) => p.theme.palette.background.primary};
`

const CameraWrapper = styled.div`
  width: 100%;
  margin: 0;
  /* max-width: 50rem; */
  position: relative;

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    width: 100%;
  }
`

const DeviceModalTemplate = ({
  title,
  device,
  options,
  camera,
  subCamera,
  afterCamera,
  info,

  tabs,
  children,

  header,
}) => {
  const { t } = useTranslation('devices')

  return (
    <PageWrapper isOnline={device && device.is_online}>
      <HeaderWrapper>
        {header}
        <Container>
          <SyncStatus
            style={{ marginBottom: '1rem' }}
            configSync={device && device.config_sync}
          />
        </Container>
      </HeaderWrapper>
      <Page>
        <CameraWrapper>
          <Info style={{ padding: 0 }} border={false} shadow={false}>
            {info}
          </Info>
          <CameraContainer>
            {camera && (
              <iframe
                title="camera-stream"
                src={camera && camera.embed}
                frameBorder="0"
                style={styles.camera}
                autoPlay={false}
              />
            )}
          </CameraContainer>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginTop: '.325rem',
            }}
          >
            {subCamera}
          </div>

          {afterCamera}
        </CameraWrapper>

        <Settings>
          <Wrapper
            background="primary"
            style={{ flexDirection: 'column' }}
            // shadow={false}
            border={false}
          >
            <Typography variant="h5">{t('devices:any.settings')}</Typography>
            {tabs}
            <div style={{ marginTop: '2rem' }}>{children}</div>
          </Wrapper>
        </Settings>
      </Page>
    </PageWrapper>
  )
}

export default DeviceModalTemplate
