import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import API from 'utils/api'
import { buildUrl, clamp } from 'utils/helpers'
import config from 'config'

const REPLACED = 'REPLACED'
const resolveLatest = (fn) => {
  const current = { value: {} }
  return (...args) => {
    const now = {}
    current.value = now
    return Promise.resolve(args)
      .then((args) => fn(...args))
      .then((resolve) =>
        current.value === now ? resolve : Promise.reject(REPLACED)
      )
  }
}

export const fetchAddresses = createAsyncThunk(
  'addresses/fetchList',
  async (
    {
      search,
      limit = 20,
      page = 1,
      // filters = {
      //   is_installed: undefined,
      //   is_online: undefined,
      // }, //  { linked: null, is_online: null }
      sort = { by: 'address', order: 'desc' }, //  { by: null, order: null }
      address_uuid = undefined,
    },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const call = resolveLatest(() =>
        API.request(
          `${buildUrl(`/addresses`, {
            search,
            limit,
            page,
            // address_uuid,
            sort_by: sort?.by,
            order: sort?.order,
          })}`,
          {},
          `${config.redirectUri}/v1`
        )
      )

      const { addresses, meta } = await call()

      return { addresses, meta, isFirstPage: page === 1 }
    } catch (err) {
      const { message } = await err.json()
      return rejectWithValue(message)
    }
  }
)

const initialState = {
  current: null,
  list: null,
  totalPages: null,
  total: null,
  page: 1,
  limit: 25,
  search: '',
  available: [],
  loadingAvailable: false,
  pending: false,
}

// reducers
export const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    clearAddresses: () => initialState,
    // incPage: (state) => {
    //   state.page = clamp(state.page + 1, 1, state.pages)
    // },
    // decPage: (state) => {
    //   state.page = clamp(state.page - 1, 1, state.pages)
    // },
    // editAddress: (state, { payload: { deviceUuid, fields } }) => {
    //   const index = state.list.findIndex((device) => device.uuid === deviceUuid)
    //   state.list[index] = { ...state.list[index], ...fields }
    // },
    setSearch: (state, { payload }) => {
      state.page = 1
      state.search = payload
    },
  },
  extraReducers: {
    [fetchAddresses.pending]: (state) => {
      state.pending = true
    },
    [fetchAddresses.fulfilled]: (
      state,
      { payload: { isFirstPage, meta, addresses } }
    ) => {
      state.pending = false
      state.totalPages = meta.total_pages
      state.total = meta.total
      state.list = isFirstPage ? addresses : [...state.list, ...addresses]
    },
    [fetchAddresses.rejected]: (state, { error, payload }) => {
      state.pending = false
    },
  },
})

export const { setSearch, clearAddresses, editDevice } = addressesSlice.actions

export default addressesSlice.reducer
