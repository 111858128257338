import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Loader } from 'sputnik-ui'
import { TablePagination } from '@material-ui/core'
import { getDeviceVideosLimit } from '../../model/selectors/getDeviceVideosLimit'
import { getDeviceVideosPage } from '../../model/selectors/getDeviceVideosPage'
import { devicesVideosActions } from '../../model/slice/slice'
import { getDeviceVideosMeta } from '../../model/selectors/getDeviceVideosMeta'
import { VideoCard } from '../VideoCard/VideoCard'
import { getDeviceVideosList } from '../../model/selectors/getDeviceVideosList'
import { getDeviceVideosPending } from '../../model/selectors/getDeviceVideosPending'

const Wrapper = styled.div`
  display: grid;
  gap: 2rem 0.75rem;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
`

export const VideoList = () => {
  const dispatch = useDispatch()
  const videosList = useSelector(getDeviceVideosList)
  const pending = useSelector(getDeviceVideosPending)
  const meta = useSelector(getDeviceVideosMeta)
  const limit = useSelector(getDeviceVideosLimit)
  const page = useSelector(getDeviceVideosPage)

  if (pending) {
    return <Loader />
  }
  return (
    <div>
      {!videosList?.length && !pending && <span>Ничего не найдено</span>}
      <Wrapper>
        {videosList &&
          videosList?.map((el) => (
            <VideoCard
              key={el?.uuid}
              uuid={el.uuid}
              device_uuid={el?.device_uuid}
              address_uuid={el?.address_uuid}
              address_type={el?.address_type}
              short_address={el?.short_address}
              embed={el?.embed}
            />
          ))}
      </Wrapper>
      <TablePagination
        component="div"
        count={meta?.total}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
        page={page}
        nextIconButtonProps={{
          disabled: page >= meta?.total_pages,
        }}
        backIconButtonProps={{
          disabled: page === 1,
        }}
        labelRowsPerPage=""
        labelDisplayedRows={({ page }) =>
          `${meta?.total_pages === 0 ? 0 : page} из ${meta?.total_pages}`
        }
        onChangePage={(e, page) => {
          dispatch(devicesVideosActions.setPage(page))
        }}
        onChangeRowsPerPage={(e) => {
          dispatch(devicesVideosActions.setLimit(e.target.value))
        }}
        style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}
      />
    </div>
  )
}
