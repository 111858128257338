import React, { useState } from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { IconButton } from '@material-ui/core'
import { LinkOff } from '@material-ui/icons'

import { ReactComponent as ISee } from 'assets/ISee.svg'
import ConfirmActionModal from 'components/organisms/ConfirmActionModal'
import { useDispatch, useSelector } from 'react-redux'
import { unlinkCamera } from 'features/Cameras/camerasSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { useParams } from 'react-router'
import { getCamerasByAddress } from 'store/devices/operations'
import { useTranslation } from 'react-i18next'
import { ShutdownFeature } from 'shared/lib/ShutdownFeature'

const Camera = ({ uuid, serial_number, onClick, children }) => {
  const { t } = useTranslation('devices')
  const [confirmActionModal, setConfirmActionModal] = useState(false)
  const dispatch = useDispatch()
  const unlinking = useSelector(({ camerasNew }) => camerasNew.unlinking)

  const { entry, house } = useParams()
  return (
    <ListItem onClick={onClick} button>
      <ListItemIcon>
        <ISee />
      </ListItemIcon>
      <ListItemText primary={serial_number} />
      <ShutdownFeature>
        <ListItemSecondaryAction>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setConfirmActionModal(true)
            }}
          >
            <LinkOff color="error" />
          </IconButton>
        </ListItemSecondaryAction>
      </ShutdownFeature>
      <ShutdownFeature>
        <ConfirmActionModal
          open={confirmActionModal}
          handleClose={(e) => {
            e.stopPropagation()
            setConfirmActionModal(false)
          }}
          handleAction={(e) => {
            e.stopPropagation()
            dispatch(unlinkCamera(uuid))
              .then(unwrapResult)
              .then(() => {
                dispatch(getCamerasByAddress(entry || house))
                setConfirmActionModal(false)
              })
          }}
          action={t('devices:cameras.unlink')}
          loading={unlinking}
        />
      </ShutdownFeature>
    </ListItem>
  )
}
export default Camera
