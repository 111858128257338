import React from 'react'
import BaseTemplate from 'components/templates/BaseTemplate'
import { DevicesVideosList } from 'features/DevicesVideosList'

function DevicesPage({ children, ...props }) {
  return (
    <BaseTemplate>
      <DevicesVideosList />
    </BaseTemplate>
  )
}

export default DevicesPage
