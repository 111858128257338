import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import warrantyGetColor from './warrantyGetColor'

const useWarrantyInputsData = () => {
  const { t } = useTranslation(['warranty', 'devices', 'common', 'people'])
  const { deviceTroubles, inputsData, statuses } = useMemo(() => {
    const statuses = [
      {
        localId: 'review',
        get color() {
          return warrantyGetColor(this.localId)
        },
        label: t('warranty:status.review'),
        data: [],
      },
      {
        localId: 'repair',
        get color() {
          return warrantyGetColor(this.localId)
        },
        label: t('warranty:status.repair'),
        data: [],
      },
      {
        localId: 'repaired',
        get color() {
          return warrantyGetColor(this.localId)
        },
        label: t('warranty:status.repaired'),
        data: [],
      },
      {
        localId: 'refusal',
        get color() {
          return warrantyGetColor(this.localId)
        },
        label: t('warranty:status.refusal'),
        data: [],
      },
    ]

    const deviceTroubles = {
      intercom: [
        {
          label: t('form.intercom.tap'),
          localId: 'tap',
          options: [
            { label: t('form.intercom.no_call'), errNum: 'tap_trouble_0' },
            { label: t('form.intercom.no_reaction'), errNum: 'tap_trouble_1' },
            {
              label: t('form.intercom.no_reaction_door_open'),
              errNum: 'tap_trouble_2',
            },
            { label: t('form.intercom.whistle'), errNum: 'tap_trouble_3' },
            { label: t('form.intercom.audibility'), errNum: 'tap_trouble_4' },
          ],
        },

        {
          label: t('form.intercom.general'),
          localId: 'intercom_general',
          options: [
            {
              label: t('form.intercom.offline'),
              errNum: 'intercom_general_trouble_0',
            },
            {
              label: t('form.intercom.no_reaction_keys'),
              errNum: 'intercom_general_trouble_1',
            },
            {
              label: t('form.intercom.other'),
              errNum: 'intercom_general_trouble_2',
            },
          ],
        },
        {
          label: t('form.intercom.camera'),
          localId: 'intercom_camera',
          type: 'radio',
          options: [
            {
              label: t('form.intercom.clicks'),
              errNum: 'intercom_camera_trouble_0',
            },
            {
              label: t('form.intercom.no_clicks'),
              errNum: 'intercom_camera_trouble_1',
            },
          ],
        },
        {
          label: t('form.intercom.lock'),
          localId: 'lock',
          options: [
            {
              label: t('form.intercom.lock_button'),
              errNum: 'lock_trouble_0',
            },
            {
              label: t('form.intercom.lock_key'),
              errNum: 'lock_trouble_1',
            },
          ],
        },
        {
          type: 'radio',
          localId: 'mechanical_damage',
          label: t('form.intercom.damage'),
          options: [
            {
              label: t('form.intercom.damage_yes'),
              errNum: true,
            },
            {
              label: t('form.intercom.damage_no'),
              errNum: false,
            },
          ],
        },
      ],
      camera: [
        {
          label: t('form.camera.troubles'),
          localId: 'inside_camera',
          type: 'radio',
          options: [
            {
              label: t('form.camera.broken'),
              errNum: 'inside_camera_trouble_0',
            },
            {
              label: t('form.camera.other'),
              errNum: 'inside_camera_trouble_1',
            },
          ],
        },
      ],
    }

    const inputsData = [
      {
        localId: 'first_column',
        fields: [
          {
            label: t('devices:serial_number'),
            name: 'device_number',
            required: true,
          },
          {
            label: t('form.company_name'),
            name: 'partner_name',
            required: true,
          },
          {
            label: t('people:contact_person'),
            name: 'full_name',
            required: true,
          },
        ],
      },

      {
        localId: 'second_column',
        fields: [
          {
            label: 'Email',
            name: 'email',
            required: true,
            type: 'email',
          },
          {
            label: t('form.phone'),
            name: 'phone',
            required: true,
            type: 'phone',
          },
          {
            label: t('form.return_address'),
            name: 'return_address',
            required: true,
          },
        ],
      },
    ]
    return { deviceTroubles, inputsData, statuses }
  }, [t])

  return { deviceTroubles, inputsData, statuses }
}

export default useWarrantyInputsData
