import React, { useCallback } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import 'utils/yup-phone'
import { Button } from 'sputnik-ui'
import { DialogActions } from '@material-ui/core'
import FormField from 'components/atoms/FormField'
import styled, { css } from 'styled-components'
import Accordion from 'components/molecules/Accordion'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import FormControl from 'components/atoms/FormControl'
import FlatKeys from 'components/molecules/FlatKeys'
import { updateFlat, getFlats } from 'store/resources/operations'
import ym from 'react-yandex-metrika'
import { ShutdownFeature } from 'shared/lib/ShutdownFeature'
import { FormArea } from './FormArea'

const gaps = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`

const StyledForm = styled(Form)`
  ${gaps}
`

const Col = styled.div`
  ${gaps}
  grid-gap: .325rem;
`

const alwaysEnableCompanies = [
  'db61f873-dcf7-4678-aec1-6b93f54ddbb2',
  '0e7236ac-b8bd-4ab0-8634-d165ad17190e',
  '627e9b3b-9162-4e1b-b588-84882e8cc445',
]

const defaultPattern =
  'Доступ приостановлен вашей обслуживающей организацией. Для уточнения причин и разблокировки, пожалуйста, обратитесь в'
const blockedDefaultMsg = {
  cyfral: `${defaultPattern} Цифрал-Сервис https://cyfral-group.ru`,
  rithm: `${defaultPattern} ТОО «Р.И.Т.М.» +77079160017 (What’s app)`,
  comfort: `${defaultPattern} "ООО Комфорт" по номеру +79018677242 или +73412677242.`,
}
const rithmUuid = 'af8b5bf4-0108-4a0c-acc2-096f94959fb3'
const isComfort = '73a5d406-6de5-4bed-b039-cfe438072c23'
const probablyCyfral = [
  '0e7236ac-b8bd-4ab0-8634-d165ad17190e',
  'db61f873-dcf7-4678-aec1-6b93f54ddbb2',
  '085b93e5-f200-4801-8eb4-dbc4c8813a3b',
  '627e9b3b-9162-4e1b-b588-84882e8cc445',
]

const getCurrentCompany = (key, contact) => {
  switch (key) {
    case rithmUuid:
      return blockedDefaultMsg.rithm
    case isComfort:
      return blockedDefaultMsg.comfort
    case probablyCyfral.includes(key):
      return blockedDefaultMsg.cyfral
    default:
      return `${defaultPattern} ${contact?.organisation} ${contact?.support_phone}`
  }
}

const schema = Yup.object().shape({
  owner_last_name: Yup.string().nullable(),
  owner_first_name: Yup.string().nullable(),
  owner_middle_name: Yup.string().nullable(),
  owner_phone: Yup.string().nullable(),
  notice: Yup.string().nullable(),
  call_blocked: Yup.boolean().nullable(),
  blocked: Yup.boolean().nullable(),
  blocking_reason: Yup.string().when('blocked', (blocked, value) =>
    blocked ? value.required().trim().max(200) : value.nullable()
  ),
})

export default function FlatForm({
  config,
  onSubmit,
  flat,
  onClose,
  isNew,
  onAddKey,
  resetSearch,
  ...props
}) {
  const isPro = useSelector(({ auth }) => auth.user.pro)
  const loading = useSelector(({ resources }) => resources.flats.loading)
  const { organisation, owner_uuid, support_phone } = useSelector(
    ({ partners }) => partners.current
  )
  const { entry } = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation(['geo', 'common', 'people'])

  const isAccessCompany = isPro || alwaysEnableCompanies.includes(owner_uuid)

  const handleSubmit = useCallback(
    (values, resetForm) => {
      const { uuid } = config
      let newConfig = {
        ...config,
        ...values,
        // call_type: config.call_blocked ? 'direct' : 'none',
      }

      if (isAccessCompany) {
        newConfig = {
          ...newConfig,
          blocking_reason: values.blocked ? values.blocking_reason : '',
        }
      }

      dispatch(updateFlat(uuid, newConfig, isAccessCompany))
        .then((flat) => {
          resetSearch && resetSearch()
          onClose()
          dispatch(getFlats({ parentUuid: entry }))
        })
        .catch(() => resetForm())
    },
    [config, dispatch, entry, isAccessCompany, onClose, resetSearch]
  )

  const handleBlock = useCallback(
    (event, values, callback, validate, setFieldTouched) => {
      setFieldTouched('blocking_reason', false)
      if (!values.blocking_reason && event.target.checked) {
        callback(
          'blocking_reason',
          getCurrentCompany(owner_uuid, {
            organisation,
            support_phone,
          })
        )
      }

      if (!event.target.checked) {
        callback('blocking_reason', '')
      }

      setTimeout(() => validate('blocking_reason'), 0)

      ym(
        'reachGoal',
        event.target.checked
          ? 'control_flats_toggleAppBlockOn'
          : 'control_flats_toggleAppBlockOff'
      )

      return event
    },
    [organisation, owner_uuid, support_phone]
  )

  return (
    <Formik
      validateOnMount
      initialValues={config}
      validationSchema={schema}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      enableReinitialize
      {...props}
    >
      {({ values, isValid, setFieldValue, validateField, setFieldTouched }) => (
        <StyledForm>
          <ShutdownFeature>
            <FormControl
              name="call_blocked"
              label={t('people:form.call_blocked')}
              onClick={(e) => {
                ym(
                  'reachGoal',
                  e.target.checked
                    ? 'control_flats_toggleCallsOn'
                    : 'control_flats_toggleCallsOff'
                )

                return e
              }}
            />
            {isAccessCompany && (
              <>
                <FormControl
                  value={values.blocked}
                  name="blocked"
                  label={t('people:form.blocked')}
                  onClick={(e) =>
                    handleBlock(
                      e,
                      values,
                      setFieldValue,
                      validateField,
                      setFieldTouched
                    )
                  }
                />
                <FormArea
                  required
                  rows="3"
                  fullWidth
                  variant="filled"
                  label={t('people:form.blocking_reason')}
                  name="blocking_reason"
                  disabled={!values.blocked}
                  autocomplete="off"
                />
              </>
            )}
          </ShutdownFeature>
          <Accordion title={t('people:contact_person')}>
            <Col>
              <FormField
                fullWidth
                name="owner_last_name"
                label={t('people:name')}
                autoComplete="surname"
              />
              <FormField
                fullWidth
                name="owner_first_name"
                label={t('people:surname')}
                autoComplete="name"
              />
              <FormField
                fullWidth
                name="owner_middle_name"
                label={t('people:middle_name')}
                autoComplete="middlename"
              />
              <FormField
                fullWidth
                name="owner_phone"
                label={t('people:phone')}
                autoComplete="phone"
                type="tel"
              />
            </Col>
          </Accordion>
          <Accordion
            title={
              <span style={{ textTransform: 'capitalize' }}>
                {t('keys:units.key_plural')}
              </span>
            }
          >
            {({ open }) =>
              open && (
                <FlatKeys
                  flat={config?.flat_number}
                  flatUuid={config?.uuid}
                  parent={config?.parent_uuid}
                  onAddKey={onAddKey}
                />
              )
            }
          </Accordion>
          <FormField
            fullWidth
            variant="filled"
            label={t('people:note')}
            multiline
            name="notice"
          />
          <DialogActions style={{ padding: 0 }}>
            <Button secondary onClick={onClose}>
              {t('common:button.close')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              rounded
              type="submit"
              disabled={loading || !isValid}
            >
              {config?.uuid
                ? t('common:button.save')
                : t('common:button.create')}
              {loading && '...'}
            </Button>
          </DialogActions>
        </StyledForm>
      )}
    </Formik>
  )
}
