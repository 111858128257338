import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Wrapper, Button } from 'sputnik-ui'
import { Typography, IconButton, Tooltip } from '@material-ui/core'
import OnlineIcon from 'components/atoms/OnlineIcon'
import { Link, useParams } from 'react-router-dom'
import { LinkOffOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import ConfirmActionModal from 'components/organisms/ConfirmActionModal'

import { fetchGate } from 'store/gates/operations'
import { unlinkDevice } from 'store/devices/operations'

const Body = styled(Wrapper)`
  display: inline-flex;
  width: auto;
  /* width: 200px; */
  flex-direction: column;

  /* width: 200px; */
`

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
  },
  intercom: {
    margin: '0 .325rem .325rem 0',
  },
}

const Intercom = styled(Button)`
  && {
    color: ${(p) => p.theme.palette.text.primary};
    border-color: ${(p) => p.theme.palette.other.border};
  }
`

export default function HouseRect({
  short_address,
  entry_intercoms,
  uuid: houseUuid,
  ...props
}) {
  const { t } = useTranslation(['geo', 'devices'])

  const dispatch = useDispatch()

  const [unlinkHouseModal, setUnlinkHouseModal] = useState(false)
  const { unlinkingDevice, gate } = useSelector(({ devices, gates }) => ({
    unlinkingDevice: devices.unlinkingDevice,
    gate: gates.current,
  }))
  const handleUnlink = useCallback(() => {
    dispatch(unlinkDevice(gate.intercom_uuid, houseUuid)).then(() => {
      dispatch(fetchGate(gate.uuid))
      setUnlinkHouseModal(false)
    })
  }, [dispatch, gate.intercom_uuid, gate.uuid, houseUuid])

  return (
    <Body background="primary" shadow={false}>
      <Typography variant="h6" gutterBottom style={styles.title}>
        {short_address}
        <Tooltip title={t('geo:actions.unlink_house')}>
          <IconButton
            style={{ marginLeft: 'auto' }}
            onClick={() => setUnlinkHouseModal(!unlinkHouseModal)}
          >
            <LinkOffOutlined />
          </IconButton>
        </Tooltip>
      </Typography>

      <div>
        {entry_intercoms?.map((i) => (
          <Link
            key={i.uuid}
            to={`/intercom/${i.uuid}`}
            style={{ textDecoration: 'none' }}
          >
            <Intercom variant="outlined" size="small" style={styles.intercom}>
              {/* <OnlineIcon
                isOnline={i.is_online}
                style={{ marginRight: '.325rem' }}
                variant="power"
              /> */}
              {i.motherboard_number}
            </Intercom>
          </Link>
        ))}
      </div>

      <ConfirmActionModal
        open={unlinkHouseModal}
        action={t('devices:actions.unlink_gate')}
        handleAction={handleUnlink}
        handleClose={() => setUnlinkHouseModal(!unlinkHouseModal)}
        loading={unlinkingDevice}
      />
    </Body>
  )
}
