import React from 'react'
import { Search, Wrapper, Button } from 'sputnik-ui'
import { useHistory, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import { CircularProgress, Typography } from '@material-ui/core'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

import FilterButton from 'components/atoms/FilterButton'
import DevicesList from 'components/molecules/DevicesList'
import FiltersWrapper from 'components/atoms/FiltersWrapper'

import { useTranslation } from 'react-i18next'
import useDevices from 'hooks/useDevices'
import { ShutdownFeature } from 'shared/lib/ShutdownFeature'

const Suggestions = styled(Wrapper)`
  /* position: absolute; */

  flex-direction: column;
  z-index: 1;
  padding: 0;
  overflow: hidden;
  position: relative;
`

const styles = { sortIcon: { width: 14, height: 14, marginLeft: 4 } }

export default function DevicesSearchList() {
  const history = useHistory()
  const match = useRouteMatch()
  const searchInputWrapperRef = React.useRef()

  const {
    filters,
    sort,
    bind,
    handleSort,
    setFilters,
    devices,
    loading,
    incrementPage,
    total,
  } = useDevices()

  // bind hotkeys
  const [firstInstalled, setFirstInstalled] = React.useState()
  const redirectToIntercom = React.useCallback(
    (e) => {
      // enter pressed
      if (e.keyCode === 13 && match.url === '/') {
        if (firstInstalled) {
          const url = `/e/${firstInstalled.address_uuid}/`
          history.push(url)
        }
      }
    },
    [firstInstalled, history, match.url]
  )
  React.useEffect(() => {
    window.addEventListener('keydown', redirectToIntercom)
    return () => {
      window.removeEventListener('keydown', redirectToIntercom)
    }
  }, [redirectToIntercom])

  const { t } = useTranslation(['common', 'devices'])

  return (
    <div
      style={{
        marginTop: '2rem',
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <div
        style={{ position: 'sticky', top: 0, zIndex: 2 }}
        ref={searchInputWrapperRef}
      >
        <Search
          style={{ width: '100%' }}
          placeholder={t('devices:placeholder.search')}
          onFocus={() =>
            searchInputWrapperRef.current &&
            searchInputWrapperRef.current.scrollIntoView({
              behavior: 'smooth',
            })
          }
          {...bind}
        />
      </div>
      <FiltersWrapper>
        <FilterButton
          style={{ marginRight: '1rem' }}
          selected={sort.by === 'address'}
          onClick={() => handleSort('address')}
        >
          {t('common:filters.by_address')}{' '}
          {sort.by === 'address' &&
            (sort.order === 'desc' ? (
              <ArrowDownward style={styles.sortIcon} />
            ) : (
              <ArrowUpward style={styles.sortIcon} />
            ))}
        </FilterButton>
        <ShutdownFeature>
          <FilterButton
            selected={sort.by === 'serial_number'}
            onClick={() => handleSort('serial_number')}
            style={{ marginRight: '1rem' }}
          >
            {t('common:filters.by_serial')}
            {sort.by === 'serial_number' &&
              (sort.order === 'desc' ? (
                <ArrowDownward style={styles.sortIcon} />
              ) : (
                <ArrowUpward style={styles.sortIcon} />
              ))}
          </FilterButton>{' '}
          <FilterButton
            selected={filters.online}
            color="green"
            style={{ marginRight: '1rem' }}
            onClick={() => {
              setFilters({ ...filters, online: !filters.online })
            }}
          >
            {t('common:filters.online')}
          </FilterButton>
          <FilterButton
            selected={filters.offline}
            color="green"
            style={{ marginRight: '1rem' }}
            onClick={() => {
              setFilters({ ...filters, offline: !filters.offline })
            }}
          >
            {t('common:filters.offline')}
          </FilterButton>
          <FilterButton
            selected={filters.installed}
            onClick={() => {
              setFilters({ ...filters, installed: !filters.installed })
            }}
            color="lightblue"
          >
            {t('common:filters.installed')}
          </FilterButton>
          <FilterButton
            selected={filters.uninstalled}
            onClick={() => {
              setFilters({ ...filters, uninstalled: !filters.uninstalled })
            }}
            color="lightblue"
          >
            {t('common:filters.uninstalled')}
          </FilterButton>
          <FilterButton
            selected={filters.device_type.intercom}
            onClick={() =>
              setFilters({
                ...filters,
                device_type: {
                  ...filters.device_type,
                  intercom: filters.device_type.intercom
                    ? undefined
                    : !filters.device_type.intercom,
                },
              })
            }
            color="pink"
          >
            {t('common:filters.intercom')}
          </FilterButton>
          <FilterButton
            selected={filters.device_type.camera}
            onClick={() =>
              setFilters({
                ...filters,
                device_type: {
                  ...filters.device_type,
                  camera: filters.device_type.camera
                    ? undefined
                    : !filters.device_type.camera,
                },
              })
            }
            color="pink"
          >
            {t('common:filters.camera')}
          </FilterButton>
        </ShutdownFeature>
      </FiltersWrapper>

      <Suggestions background="primary" shadow={false} border={false}>
        <Typography>
          {t('common:filters.by_filters')}
          {total}
        </Typography>
        <DevicesList
          loading={loading}
          devices={devices}
          firstInstalled={firstInstalled}
        />

        {loading && (
          <div
            style={{
              height: '100%',
              width: '100%',
              background: 'rgba(255,255,255,.7)',
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              minHeight: 400,
              pointerEvents: 'none',
            }}
          >
            <CircularProgress style={{ marginTop: '1rem auto' }} />
          </div>
        )}
      </Suggestions>
      {/* {typeof children === 'function' && children({ onScroll })} */}
    </div>
  )
}
