import { createAsyncThunk } from '@reduxjs/toolkit'
import config from 'config'
import { showErrorMessage } from 'store/ui/actions'
import api from 'utils/api'

const buildQueryParamsFromArray = (key, array) => {
  if (array.length < 1) {
    return `${key}=[]`
  }
  return array
    .map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
    .join('&')
}

export const fetchCamerasByUuid = createAsyncThunk(
  'devicesVideos/videos',
  async ({ addressUuid }, { rejectWithValue, dispatch, signal }) => {
    try {
      const params = buildQueryParamsFromArray('address_uuid[]', addressUuid)

      const payload = await api.request(
        `/cameras?${params}`,
        { signal },
        config.baseUri
      )

      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)
