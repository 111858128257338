import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Input, Radio, TextArea, Checkbox, Button } from 'sputnik-ui'
import { FormControlLabel, RadioGroup, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { unwrapResult } from '@reduxjs/toolkit'
import { getDeviceTypeByNumber, isDeviceUuidValid } from 'utils/helpers'
import Toggle from './WarrantyToggle'
import useWarrantyInputsData from './useWarrantyInputsData'
import {
  checkWarrantyAvailable,
  createWarranty,
  fetchWarranty,
} from './warrantySlice'
import { OutOfWarrantyModal } from './OutOfWarrantyModal'
import { SuccessWarrantyOrder } from './SuccessWarrantyOrder'

const SectionWrapper = styled.div`
  width: 100%;
  padding: 1.5rem;
  border: 1px solid #ededed;
  border-radius: 20px;
  margin-bottom: 1.5rem;
`

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 15px;
  white-space: nowrap;
  &:first-letter {
    text-transform: uppercase;
  }
`

const SectionInput = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
`

const SectionCheck = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

const validationSchema = Yup.object().shape({
  device_number: Yup.string()
    .required()
    .test('len', 'serial required', (val) => {
      const len = val?.toString().trim().length
      return len === 16 || len === 10
    }),
  partner_name: Yup.string().trim().required(),
  full_name: Yup.string().trim().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().phone().required(),
  return_address: Yup.string().trim().required(),
})

const StyledTypography = styled(Typography)`
  && {
    font-family: ${(p) => p.theme.fonts.openSans};
    font-size: 16px;
    font-weight: 17.6px;
  }
`

const variables = {
  intercom: 'intercom',
  camera: 'camera',
}

function WarrantyForm({ closeModal, ...props }) {
  const { t } = useTranslation(['warranty', 'devices'])
  const [toggle, setToggle] = useState(variables.intercom)
  const [area, setArea] = useState(false)
  const { deviceTroubles, inputsData } = useWarrantyInputsData()
  const [isWarrantyModal, setIsWarrantyModal] = useState(false)
  const user = useSelector(({ auth }) => auth.user)
  const buttonPending = useSelector(({ warranty }) => warranty.createPending)
  const dispatch = useDispatch()

  const initialValues = {
    device_number: '',
    partner_name: user?.company.name || '',
    full_name: user?.full_name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    return_address: '',
    tap: [],
    lock: [],
    mechanical_damage: false,
    intercom_general: [],
    intercom_camera: '',
    inside_camera: '',
  }

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema,
    onSubmit: (values) => {
      const {
        tap,
        intercom_camera,
        lock,
        mechanical_damage,
        intercom_general,
        inside_camera,
        return_address,
        email,
        phone,
        add_info,
        device_number,
        partner_name,
        full_name,
      } = values
      dispatch(
        createWarranty({
          values: {
            device_kind: toggle,
            add_info: add_info || undefined,
            return_address,
            email,
            phone,
            device_number,
            partner_name,
            full_name,
          },
          troubles: {
            tap: tap.length ? tap : undefined,
            intercom_camera: intercom_camera || undefined,
            lock: lock.length ? lock : undefined,
            mechanical_damage:
              mechanical_damage === true ? mechanical_damage : undefined,
            intercom_general: intercom_general.length
              ? intercom_general
              : undefined,
            inside_camera: inside_camera || undefined,
          },
        })
      )
        .then(unwrapResult)
        .then(closeModal)
        .then(() => dispatch(fetchWarranty()))
    },
  })

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    handleBlur,
    setValues,
    resetForm,
  } = formik

  const customHandleBlur = (e) => {
    if (
      e.target.name === 'device_number' &&
      isDeviceUuidValid(e.target.value)
    ) {
      dispatch(checkWarrantyAvailable({ hex: e.target.value }))
        .then(unwrapResult)
        .then((isWarrantyAvailable) => {
          setIsWarrantyModal(!isWarrantyAvailable)
        })
    }
    handleBlur(e)
  }

  const customHandleChange = (val, id) => {
    const { value } = val.target
    if (values[id].includes(value)) {
      setFieldValue(id, [...values[id].filter((el) => el !== value)])
    } else {
      setFieldValue(id, [...values[id], value])
    }
  }

  const radioToggleHandler = (radioVal, id) => {
    const val = radioVal.target.value
    if (val === 'true' || val === 'false') {
      const value = val === 'true'
      setFieldValue(id, !!value)
    } else {
      setFieldValue(id, val)
    }
  }

  useMemo(() => {
    const bol =
      values.intercom_general.includes('intercom_general_trouble_2') ||
      values.inside_camera.includes('inside_camera_trouble_1')

    setArea(bol)
    if (bol) {
      setFieldValue('add_info', values?.add_info)
    } else {
      setFieldValue('add_info', undefined)
    }
  }, [
    values.intercom_general,
    values.inside_camera,
    values?.add_info,
    setFieldValue,
  ])

  const handleToggle = () => {
    setToggle(
      toggle === variables.intercom ? variables.camera : variables.intercom
    )
    setValues({
      ...initialValues,
      return_address: values.return_address,
      device_number: values.device_number,
      email: values.email,
      phone: values.phone,
      partner_name: values.partner_name,
      full_name: values.full_name,
    })
  }

  const closeOutWarrantyModal = () => {
    setIsWarrantyModal(false)
  }

  const continueNextOutWarrantyModal = () => {
    setIsWarrantyModal(false)
    resetForm()
  }

  const disabledSubmit =
    !(
      values.tap.length ||
      values.lock.length ||
      values.intercom_general.length ||
      values.mechanical_damage ||
      values.intercom_camera.length ||
      values.inside_camera.length
    ) ||
    Object.keys(errors).length > 0 ||
    !Object.keys(touched).length > 0 ||
    (area && !values.add_info?.trim()) ||
    buttonPending ||
    getDeviceTypeByNumber(values.device_number) !== toggle

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <OutOfWarrantyModal
        open={isWarrantyModal}
        onClose={closeOutWarrantyModal}
        continueNext={continueNextOutWarrantyModal}
      />
      <SectionWrapper>
        <SectionTitle>{t('warranty:title.contact_info')}</SectionTitle>
        <SectionInput>
          {inputsData.map(({ fields, localId }) => (
            <Column key={localId}>
              {fields.map((input) => (
                <Input
                  key={input.name}
                  style={{ marginBottom: 12 }}
                  fullWidth
                  label={input?.label}
                  type={input?.type}
                  name={input?.name}
                  error={errors[input?.name] && touched[input?.name]}
                  onBlur={customHandleBlur}
                  required={input?.required}
                  onChange={handleChange}
                  value={values[input?.name]}
                  autoComplete="on"
                />
              ))}
            </Column>
          ))}
        </SectionInput>
      </SectionWrapper>
      <SectionTitle> {t('warranty:title.info')}</SectionTitle>
      <Toggle
        style={{ maxWidth: 320, marginBottom: '1.5rem' }}
        label1={t('devices:units.intercom')}
        label2={t('devices:units.camera')}
        checked={toggle === variables.camera}
        onChange={handleToggle}
      />
      {toggle === variables.intercom ? (
        <SectionWrapper>
          <SectionCheck>
            {deviceTroubles.intercom.map(
              ({ label, localId, options, type }) => (
                <Column key={localId}>
                  <SectionTitle>{label}</SectionTitle>
                  {type === 'radio' ? (
                    <RadioGroup
                      onChange={(e) => radioToggleHandler(e, localId)}
                      value={values[localId]}
                    >
                      {options.map((option) => (
                        <FormControlLabel
                          key={option.label}
                          label={
                            <StyledTypography>{option.label}</StyledTypography>
                          }
                          value={option.errNum}
                          control={<Radio color="blue" />}
                        />
                      ))}
                    </RadioGroup>
                  ) : (
                    <>
                      {options?.map((option) => (
                        <FormControlLabel
                          key={option.errNum}
                          label={
                            <StyledTypography>{option.label}</StyledTypography>
                          }
                          value={option.errNum}
                          onChange={(event) =>
                            customHandleChange(event, localId)
                          }
                          control={
                            <Checkbox
                              color="blue"
                              checked={values[localId].includes(option.errNum)}
                            />
                          }
                        />
                      ))}
                    </>
                  )}
                </Column>
              )
            )}
          </SectionCheck>
        </SectionWrapper>
      ) : (
        <SectionWrapper>
          {deviceTroubles.camera.map(({ localId, label, type, options }) => (
            <Column key={localId}>
              <SectionTitle>{label}</SectionTitle>
              <RadioGroup
                onChange={(e) => radioToggleHandler(e, localId)}
                value={values[localId]}
              >
                {options.map((option) => (
                  <FormControlLabel
                    key={option.label}
                    label={<StyledTypography>{option.label}</StyledTypography>}
                    value={option.errNum}
                    control={<Radio color="blue" />}
                  />
                ))}
              </RadioGroup>
            </Column>
          ))}
        </SectionWrapper>
      )}

      {area && (
        <TextArea
          placeholder={t('warranty:form.comment_placeholder')}
          name="add_info"
          value={values?.add_info}
          onChange={handleChange}
          style={{ marginBottom: '1.5rem' }}
          rows="4"
        />
      )}

      <div>
        <Button type="submit" primary variant="v2" disabled={disabledSubmit}>
          {t('warranty:action.register')} {buttonPending && '...'}
        </Button>
      </div>
    </StyledForm>
  )
}

export default WarrantyForm
