// @flow
import * as types from './types'

// type State = {
//   +collection: ?(Object[]),
//   +error: ?string,
//   +unlinkedIntercoms: ?(Object[]),
//   +cameras: ?(Object[]),
//   +unlinkedCameras: ?(Object[]),
//   +intercom: ?Object,
//   +search: ?(Object[]),
//   +deviceType: ?'camera' | ?'intercom',
// }

// type Action = {
//   type: string,
//   payload: any,
// }

const initialState = {
  search: null,
  collection: null,
  unlinkedIntercoms: null,
  cameras: null,
  unlinkedCameras: null,
  intercom: null,
  error: null,
  msg: null,
  deviceType: null,
  unlinked: null,
  loading: false,
  presets: null,
  linkPending: false,
  loadingIntercoms: false,
  unlinkingDevice: false,
}

export default function devices(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_UNLINKED_DEVICES_SUCCESS:
      return { ...state, unlinked: payload.unlinkedDevices }
    case types.GET_INTERCOMS_BY_ADDRESS_REQUEST:
      return { ...state, loadingIntercoms: true }
    case types.GET_INTERCOMS_BY_ADDRESS_SUCCESS:
      return {
        ...state,
        collection: payload.intercoms,
        error: null,
        loadingIntercoms: false,
      }
    case types.GET_INTERCOMS_BY_ADDRESS_ERROR:
      return { ...state, error: payload, loadingIntercoms: false }
    case types.GET_INTERCOMS_BY_IDENTIFIER_REQUEST:
      return { ...state, loading: true }
    case types.GET_INTERCOMS_BY_IDENTIFIER_SUCCESS:
      return payload.firstPage
        ? { ...state, search: payload.intercoms, loading: false }
        : {
            ...state,
            search: [...state.search, ...payload.intercoms],
            loading: false,
          }
    case types.GET_INTERCOMS_BY_IDENTIFIER_ERROR:
      return { ...state, loading: false }
    case types.GET_UNLINKED_INTERCOMS_SUCCESS:
      return { ...state, unlinkedIntercoms: payload.intercoms, error: null }
    case types.GET_UNLINKED_INTERCOMS_ERROR:
      return { ...state, error: payload }
    case types.GET_CAMERAS_BY_ADDRESS_SUCCESS:
      return { ...state, cameras: payload.cameras, error: null }
    case types.GET_CAMERAS_BY_ADDRESS_ERROR:
      return { ...state, error: payload }
    case types.GET_UNLINKED_CAMERAS_SUCCESS:
      return { ...state, unlinkedCameras: payload.cameras, error: null }
    case types.GET_UNLINKED_CAMERAS_ERROR:
      return { ...state, error: payload }
    case types.LINK_DEVICE_REQUEST:
      return { ...state, linkPending: true }
    case types.LINK_DEVICE_SUCCESS:
      return { ...state, msg: payload, error: null, linkPending: false }
    case types.LINK_DEVICE_ERROR:
      return { ...state, msg: null, error: payload, linkPending: false }
    case types.UNLINK_DEVICE_REQUEST:
      return { ...state, unlinkingDevice: true }
    case types.UNLINK_DEVICE_SUCCESS:
      return { ...state, msg: payload, error: null, unlinkingDevice: false }
    case types.UNLINK_DEVICE_ERROR:
      return { ...state, msg: null, error: payload, unlinkingDevice: false }
    case types.GET_CAMERA_SUCCESS:
      return { ...state, camera: payload, error: null }
    case types.GET_CAMERA_ERROR:
      return { ...state, error: payload }
    case types.SYNC_INTERCOM_SUCCESS:
      return { ...state, msg: payload }
    case types.SYNC_INTERCOM_ERROR:
      return { ...state, error: payload }
    case types.SYNC_CAMERA_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.SYNC_CAMERA_ERROR:
      return { ...state, error: payload.error }
    case types.FETCH_INTERCOM_BY_UUID_SUCCESS:
      return { ...state, intercom: payload.intercom }
    case types.OPEN_DOOR_REQUEST:
      return { ...state, loading: true }
    case types.OPEN_DOOR_SUCCESS:
      return { ...state, msg: payload.msg, loading: false }
    case types.OPEN_DOOR_ERROR:
      return { ...state, error: payload.error, loading: false }
    case types.UPDATE_DEVICE_SETTINGS_REQUEST:
      return { ...state, loading: true }
    case types.UPDATE_DEVICE_SETTINGS_SUCCESS:
      return { ...state, msg: payload.msg, loading: false }
    case types.UPDATE_DEVICE_SETTINGS_FAILURE:
      return { ...state, error: payload.error, loading: false }
    case types.UPDATE_INTERCOMS_LIST_STATUS:
      return {
        ...state,
        collection:
          state.collection &&
          state.collection.map((intercom) =>
            intercom.uuid === payload.uuid
              ? { ...intercom, is_online: payload.is_online }
              : intercom
          ),
      }
    case types.UPDATE_CURRENT_INTERCOM_STATUS:
      return {
        ...state,
        intercom: {
          ...state.intercom,
          is_online: payload.is_online,
        },
      }
    case types.UPDATE_CURRENT_INTERCOM_DOOR_STATUS:
      return {
        ...state,
        intercom: {
          ...state.intercom,
          door_opened: payload.door_opened,
        },
      }
    case types.CLEAR_CURRENT_DEVICE:
      return { ...state, intercom: null }
    case types.FETCH_SOUND_PRESETS_SUCCESS:
      return { ...state, soundPresets: payload.presets }
    case types.UPDATE_COMPANY_INTERCOMS_LIST_STATUS:
      return state.search
        ? {
            ...state,
            search: state.search.map((intercom) =>
              intercom.uuid === payload.intercom.uuid
                ? {
                    ...intercom,
                    is_online: payload.intercom.is_online === 'true',
                  }
                : intercom
            ),
          }
        : state
    case 'resources/CLEAR':
      return initialState
    case 'ui/CLEAR_STATUS':
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
