import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import API from 'utils/api'
import { buildUrl, clamp } from 'utils/helpers'
import config from 'config'

const REPLACED = 'REPLACED'
const resolveLatest = (fn) => {
  const current = { value: {} }
  return (...args) => {
    const now = {}
    current.value = now
    return Promise.resolve(args)
      .then((args) => fn(...args))
      .then((resolve) =>
        current.value === now ? resolve : Promise.reject(REPLACED)
      )
  }
}

export const fetchDevices = createAsyncThunk(
  'devices/fetchList',
  async (
    {
      search,
      limit = 20,
      page = 1,
      filters = {
        is_installed: undefined,
        is_online: undefined,
      }, //  { linked: null, is_online: null }
      sort = { by: 'address', order: 'desc' }, //  { by: null, order: null }
      address_uuid = undefined,
      device_type = undefined,
    },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const call = resolveLatest(() =>
        API.request(
          `${buildUrl(`/devices`, {
            search,
            limit,
            page,
            device_type,
            address_uuid,
            sort_by: sort?.by,
            order: sort?.order,
            is_online: filters?.is_online,
            is_installed: filters?.is_installed,
          })}`,
          {},
          `${config.redirectUri}/v1`
        )
      )

      const { devices, meta } = await call()

      return { devices, meta, isFirstPage: page === 1 }
    } catch (err) {
      const { message } = await err.json()
      return rejectWithValue(message)
    }
  }
)

/*
export const getIntercomsByIdentifier = (
  identifier: string,
  limit: string = 20,
  page: number = 1,
  filters: {
    linked: null,
    is_online: null,
  },
  sort: {
    by: null,
    order: null,
  },
) => async (dispatch) => {
  dispatch(actions.getIntercomsByIdentifierRequest(identifier, limit, page))

  try {
    const setFilters = () => {
      let params = []
      if (filters.is_online !== null && filters.is_online !== undefined)
        params.push(`is_online=${filters.is_online}`)
      if (filters.linked !== null && filters.linked !== undefined)
        params.push(`linked=${filters.linked === true ? filters.linked : ''}`)
      params = params.join('&')
      return params.length > 0 ? `&${params}` : params
    }

    const intercoms = await Api.request(
      `/intercoms?q=${identifier}&page=${page}&limit=${limit}&sort_by=${
        sort?.by
      }&order=${sort?.order}${setFilters()}`,
    )
    dispatch(actions.getIntercomsByIdentifierSuccess(intercoms, page === 1))
    return Promise.resolve(intercoms)
  } catch (err) {
    console.log(err)
    const { message } = await err.json()
    dispatch(actions.getIntercomsByIdentifierError(message))
    return Promise.reject(err)
  }
}
*/

const initialState = {
  current: null,
  list: null,
  totalPages: null,
  total: null,
  page: 1,
  limit: 25,
  search: '',
  available: [],
  loadingAvailable: false,
  pending: false,
  intercoms: null,
  cameras: null,
}

// reducers
export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    clearDevices: () => initialState,
    incPage: (state) => {
      state.page = clamp(state.page + 1, 1, state.pages)
    },
    decPage: (state) => {
      state.page = clamp(state.page - 1, 1, state.pages)
    },
    editDevice: (state, { payload: { deviceUuid, fields } }) => {
      const index = state.list.findIndex((device) => device.uuid === deviceUuid)
      state.list[index] = { ...state.list[index], ...fields }
    },
    setSearch: (state, { payload }) => {
      state.page = 1
      state.search = payload
    },
  },
  extraReducers: {
    [fetchDevices.pending]: (state) => {
      state.pending = true
    },
    [fetchDevices.fulfilled]: (
      state,
      { payload: { isFirstPage, meta, devices } }
    ) => {
      state.pending = false
      state.totalPages = meta.total_pages
      state.total = meta.total
      state.list = isFirstPage ? devices : [...state.list, ...devices]

      const intercoms = devices.filter((d) => d.type === 'intercom')
      const cameras = devices.filter((d) => d.type === 'camera')

      state.intercoms = isFirstPage
        ? intercoms
        : [...(state?.intercoms || []), ...intercoms]
      state.cameras = isFirstPage
        ? cameras
        : [...(state?.cameras || []), ...cameras]
    },
    [fetchDevices.rejected]: (state, { error, payload }) => {
      state.pending = false
    },
  },
})

export const { setSearch, clearDevices, editDevice } = devicesSlice.actions

export default devicesSlice.reducer
