// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'

import styled from 'styled-components'
import BaseTemplate from '../BaseTemplate'

const Content = styled.div`
  display: grid;
  z-index: 1;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'left right';
  height: fit-content;

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      'right'
      'left';
  }
`

const Right = styled.div`
  grid-area: right;
  display: grid;
  grid-gap: 1rem;
  align-items: start;
  grid-auto-rows: min-content;
`
const Left = styled.div`
  grid-area: left;
`
// const Center = styled.div`
//   grid-area: center;
// `

const EntryPageTemplate = ({
  header,
  subheader,
  center,
  right,
  left,
  map,
  children,
  ...props
}) => (
  <BaseTemplate menu {...props} header={header}>
    <Content>
      {/* {center && <Center>{center}</Center>} */}
      {right && <Right>{right}</Right>}
      {left && <Left>{left}</Left>}
      {children}
    </Content>
  </BaseTemplate>
)

// EntryPageTemplate.propTypes = {
//   children: PropTypes.any.isRequired,
// }

export default EntryPageTemplate
