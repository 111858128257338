import React from 'react'
import styled from 'styled-components'
import { Button, Container } from 'sputnik-ui'

const Wrapper = styled.div`
  width: 100%;
  background: #f3f3f4;
  /* overflow: auto; */
  overflow: visible;
  /* -webkit-overflow-scrolling: touch; */
`

const Content = styled(Container)`
  padding-top: 2rem;
  overflow: hidden;
  height: auto;
  padding-bottom: ${(p) => (p.menu ? 'calc(2rem + 150px)' : '2rem')};
`

const Header = styled(Container)`
  padding-top: 2rem;
  overflow: hidden;
  height: auto;
`

function BaseTemplate({ children, menu, header, ...props }) {
  return (
    <Wrapper>
      {header && <Header>{header}</Header>}
      <Content menu={Boolean(menu)}>{children}</Content>
    </Wrapper>
  )
}

export default BaseTemplate
