/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { DropList, Typography } from 'sputnik-ui'
import { Bento, IconColors, ArrowDown } from 'sputnik-icons'
import { useSelector } from 'react-redux'
import { getDeviceVideosLimit } from '../../model/selectors/getDeviceVideosLimit'

export function LimitsSelector({
  anchorEl,
  handleCloseDropList,
  handleOpenDropList,
  children,
}) {
  const limit = useSelector(getDeviceVideosLimit)
  const [openDropList, setOpenDropList] = useState(false)

  return (
    <>
      <div
        role="button"
        onClick={(e) => {
          handleOpenDropList(e)
          setOpenDropList(true)
        }}
        style={{ cursor: 'pointer' }}
      >
        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <Bento color={IconColors.black} />
          <Typography>{limit}</Typography>
          <ArrowDown
            color={IconColors.black}
            style={{ transform: 'rotate: 90;' }}
          />
        </div>
      </div>
      <DropList
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={openDropList}
        close={(e) => {
          setOpenDropList(false)
          handleCloseDropList(e)
        }}
        anchorEl={anchorEl}
      >
        {children}
      </DropList>
    </>
  )
}
