/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Typography } from 'sputnik-ui'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  min-width: 340px;
  max-width: 424px;
`

const VideoContainer = styled.div`
  position: relative;
  aspect-ratio: 16/9;
  height: 240px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`

const ShortAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

export const VideoCard = (props) => {
  const history = useHistory()
  const {
    address_uuid,
    address_type,
    short_address,
    embed,
    device_uuid,
    uuid,
  } = props

  const handleRedirect = () => {
    switch (address_type) {
      case 'house':
        history.push(`/h/${address_uuid}`)
        break
      case 'gate':
        history.push(`/gate/${address_uuid}`)
        break
      default:
        history.push(`/e/${address_uuid}`)
    }
  }

  return (
    <Wrapper>
      <VideoContainer>
        <div
          onClick={handleRedirect}
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 2,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
        <iframe
          src={`${embed}&autoplay`}
          title="video"
          style={{
            border: 'none',
            position: 'absolute',
            borderRadius: 8,
            top: 0,
            zIndex: 1,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgb(250, 251, 250)',
          }}
        />
      </VideoContainer>
      <ShortAddress>
        <Typography variant="body2">{short_address}</Typography>
      </ShortAddress>
    </Wrapper>
  )
}
