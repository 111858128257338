// @flow
import React, { useState, useEffect, useCallback } from 'react'
import { EntryPageTemplate, Entries, Cameras, CameraModal } from 'components'
import { withRouter } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import Map from 'components/atoms/Map'
import { IconButton, List, LinearProgress } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Wrapper, Button, Radio, Typography } from 'sputnik-ui'

import {
  getCamerasByAddress,
  getIntercomsByAddress,
  fetchHouse,
} from 'store/operations'
import { clearResources } from 'store/actions'
import { getEntries } from 'store/resources/operations'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeviceSearchSuggestion from 'components/atoms/DeviceSearchSuggestion'
import ConfirmActionModal from 'components/organisms/ConfirmActionModal'
import { unlinkDevice } from 'store/devices/operations'
import { fetchMapIntercoms } from 'store/maps/operations'
import { updateGate } from 'store/gates/operations'
import AttachNewDeviceModal from 'features/Devices/AttachNewDeviceModal'
import { fetchDevices, clearDevices } from 'features/Devices/devicesSlice'

import ModalVideoArchive from 'features/Entry/ModalVideoArchive'
import styled from 'styled-components'
import { ShutdownFeature } from 'shared/lib/ShutdownFeature'

const StyledList = styled(List)`
  li {
    width: 100%;
  }
`

function HousePage({
  match: {
    params: { house },
  },
  dispatch,
  camera,
  cameras,
  intercoms,
  currentAddress,
  lat,
  lon,
  mapIntercoms,
  linkPending,
  loadingIntercoms,
  unlinkingDevice,
  ...props
}) {
  const companyUuid = useSelector(({ auth }) => auth.user.company.uuid)
  useEffect(() => {
    if (house) {
      dispatch(fetchHouse(house))
      dispatch(getEntries({ parentUuid: house }))
      dispatch(fetchMapIntercoms(house))
      dispatch(fetchDevices({ address_uuid: house }))
    }
    return () => dispatch(clearDevices())
  }, [dispatch, house])

  const [attachNewIntercomModal, setAttachNewDeviceModal] = useState(false)
  const [cameraModal, setCameraModal] = useState(false)

  const [selected, setSelected] = useState(null)
  const [isCamera, setIsCamera] = useState(false)

  const [downloadVideoArchive, setDownloadVideoArchive] = useState(false)
  // const [unlinkDeviceModal, setUnlinkDeviceModal] = React.useState(false)
  const [currentDevice, setCurrentDevice] = useState(null)

  const { t } = useTranslation(['devices', 'messages'])

  const handleUnlink = useCallback(() => {
    dispatch(unlinkDevice(currentDevice, house)).then(() => {
      setCurrentDevice(null)
      dispatch(fetchDevices({ address_uuid: house }))
      dispatch(fetchMapIntercoms(house))
    })
  }, [currentDevice, dispatch, house])

  const handleToggleModalArchive = () => {
    setDownloadVideoArchive(!downloadVideoArchive)
  }

  const selectArchiveDevice = (deviceId, camera = false) => {
    setIsCamera(camera)
    setSelected(deviceId)
  }

  return (
    <EntryPageTemplate
      header={<Typography variant="title">Страница дома</Typography>}
      map={
        <Map
          withConfirm
          initialMarkers={mapIntercoms}
          center={{ lat, lon }}
          onUpdate={({ uuid, lat, lon }) =>
            dispatch(updateGate({ uuid, lat, lon }))
          }
          // initialMarkers={intercoms?.map((i) => ({
          //   ...i,
          //   ...i.installation_point,
          // }))}
        />
      }
      left={<Entries {...props} />}
      right={
        <>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h4" style={{ marginBottom: 10 }}>
              {t('devices:units.device_plural')}
            </Typography>
            <div>
              <ShutdownFeature>
                <Button
                  style={{ marginRight: '1rem' }}
                  onClick={() => setAttachNewDeviceModal(true)}
                  variant="contained"
                  primary
                  rounded
                >
                  {t('devices:actions.install')}
                </Button>
              </ShutdownFeature>
              {/* <Button
                primary
                variant="v2"
                rounded
                onClick={handleToggleModalArchive}
              >
                {t('devices:video_archive.action')}
              </Button> */}
            </div>
          </div>
          <Wrapper
            background="primary"
            shadow={false}
            style={{ flexDirection: 'column', padding: 0 }}
          >
            <div style={{ display: 'flex', margin: '1rem', marginBottom: 0 }}>
              <Typography variant="h5">
                {t('devices:units.gate_plural')}
              </Typography>
            </div>
            <StyledList>
              {intercoms ? (
                <>
                  {loadingIntercoms && (
                    <LinearProgress style={{ marginBottom: '1rem' }} />
                  )}
                  {intercoms.length > 0 ? (
                    intercoms.map((d) => (
                      <div
                        key={d.uuid}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <DeviceSearchSuggestion
                          {...d}
                          secondaryAction={
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault()
                                setCurrentDevice(d.uuid)
                              }}
                            >
                              <Close />
                            </IconButton>
                          }
                          withAddress={false}
                        />
                        <Radio
                          color="blue"
                          checked={selected === d.uuid}
                          onClick={() => selectArchiveDevice(d.uuid)}
                        />
                      </div>

                      // <Device isGate kind="sputnik" device={d} key={d.uuid} />
                    ))
                  ) : (
                    <Typography
                      color="textSecondary"
                      style={{ marginLeft: '1rem' }}
                    >
                      {t('messages:content:empty')}
                    </Typography>
                  )}
                </>
              ) : (
                <CircularProgress style={{ marginLeft: '1rem' }} />
              )}
            </StyledList>
          </Wrapper>
          {cameras && (
            <Cameras
              selectedToArchive={selected}
              setSelectedToArchive={selectArchiveDevice}
            />
          )}
        </>
      }
    >
      {/* <UnlinkDeviceModal
        open={unlinkDeviceModal}
        handleClose={() => setUnlinkDeviceModal(false)}
        device={currentDevice}
      /> */}
      <CameraModal
        open={cameraModal}
        handleClose={() => setCameraModal(false)}
        device={currentDevice}
        camera={props.camera}
      />

      <AttachNewDeviceModal
        open={attachNewIntercomModal}
        handleClose={() => setAttachNewDeviceModal(false)}
        lat={lat}
        lon={lon}
        linkPending={linkPending}
      />
      <ModalVideoArchive
        isCamera={isCamera}
        selected={selected}
        open={downloadVideoArchive}
        handleClose={handleToggleModalArchive}
      />

      <ConfirmActionModal
        open={Boolean(currentDevice)}
        action={t('devices:actions.unlink_gate')}
        handleAction={handleUnlink}
        handleClose={() => setCurrentDevice(null)}
        loading={unlinkingDevice}
      />
    </EntryPageTemplate>
  )
}

const mapStateToProps = ({ devices, devicesNew, resources, keys, maps }) => ({
  camera: devices.camera,
  intercoms: devicesNew.intercoms,
  cameras: devicesNew.cameras,
  currentAddress:
    resources.houses.current && resources.houses.current.full_address,
  lat: resources.houses.current && resources.houses.current.lat,
  lon: resources.houses.current && resources.houses.current.lon,
  mapIntercoms: maps.intercoms,
  loadingIntercoms: devicesNew.pending,
  unlinkingDevice: devices.unlinkingDevice,
})

export default connect(mapStateToProps)(withRouter(HousePage))
