import React, { useEffect, useState } from 'react'
import { Divider, Tabs } from '@material-ui/core'
import { DropListItem, Input, Tab, Typography } from 'sputnik-ui'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { getDeviceVideosAddresses } from '../../model/selectors/getDeviceVideosAddresses'
import { fetchCamerasByUuid } from '../../model/services/fetchCamerasByUuid'
import { getDeviceVideosFilter } from '../../model/selectors/getDeviceVideosFilter'
import { devicesVideosActions } from '../../model/slice/slice'
import { getDeviceVideosMeta } from '../../model/selectors/getDeviceVideosMeta'
import { getDeviceVideosSearch } from '../../model/selectors/getDeviceVideosSearch'
import { getDeviceVideosLimit } from '../../model/selectors/getDeviceVideosLimit'
import { getDeviceVideosPage } from '../../model/selectors/getDeviceVideosPage'
import { fetchAllAddresses } from '../../model/services/fetchAllAddresses'
import { LimitsSelector } from './LimitsSelector'

const tabs = [
  { label: 'Все', value: '' },
  { label: 'Домофон', value: 'intercom' },
  { label: 'Калитка', value: 'gate' },
  { label: 'Камера', value: 'camera' },
]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const gridItems = [6, 9, 12]

export const FiltersList = () => {
  const dispatch = useDispatch()
  const addresses = useSelector(getDeviceVideosAddresses)
  const filter = useSelector(getDeviceVideosFilter)
  const meta = useSelector(getDeviceVideosMeta)
  const search = useSelector(getDeviceVideosSearch)
  const limit = useSelector(getDeviceVideosLimit)
  const page = useSelector(getDeviceVideosPage)

  const [anchorEl, setAnchorEl] = useState(null)
  const handleCloseDropList = () => {
    setAnchorEl(null)
  }
  const handleOpenDropList = (event) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    dispatch(fetchCamerasByUuid({ addressUuid: addresses }))
  }, [addresses, dispatch])

  const [handleChange] = useDebouncedCallback(() => {
    const deviceType = filter !== 'gate' ? filter : undefined
    const addressType = filter === 'gate' ? filter : undefined
    dispatch(
      fetchAllAddresses({ deviceType, search, limit, page, addressType })
    )
  }, 250)

  useEffect(() => {
    handleChange()
  }, [dispatch, filter, handleChange, search, limit, page])

  const onTabChange = (e, value) => {
    dispatch(devicesVideosActions.setFilter(value))
  }

  const handleSearch = (e) => {
    const { value } = e.target
    dispatch(devicesVideosActions.setSearch(value))
  }

  const handleSelectLimit = (count) => {
    dispatch(devicesVideosActions.setLimit(count))
  }

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '1rem',
        }}
      >
        <Tabs value={filter} onChange={onTabChange} variant="scrollable">
          {tabs.map((tab) => (
            <Tab
              key={tab.label}
              style={{ marginRight: 10 }}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '1rem',
          }}
        >
          <Typography color="textSecondary">{meta?.total} устройств</Typography>
          <LimitsSelector
            handleOpenDropList={handleOpenDropList}
            handleCloseDropList={handleCloseDropList}
            anchorEl={anchorEl}
          >
            {gridItems.map((el) => (
              <DropListItem
                key={el}
                label={el}
                onClick={() => handleSelectLimit(el)}
              />
            ))}
          </LimitsSelector>
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div />
        <div>
          <Input
            placeholder="Поиск"
            onChange={handleSearch}
            value={search}
            style={{ minWidth: 260 }}
          />
        </div>
      </div>
    </Container>
  )
}
