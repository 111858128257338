/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react'
import {
  Typography,
  Tooltip,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as SputnikIcon } from 'assets/Sputnik.svg'
import { ReactComponent as ISee } from 'assets/ISee.svg'
import { StrangeCamera } from 'sputnik-icons' // fix me
import { Power, PowerOff, Archive, FileCopy } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'
import { DeviceModel, formatDeviceId, Input } from 'sputnik-ui'
// import cam from 'assets/cam.png'
// import sputnik from 'assets/sputnik.png'

import { ReactComponent as Archive3 } from 'features/services/archive-3.svg'
import { ReactComponent as Archive5 } from 'features/services/archive-5.svg'
import { ReactComponent as Archive7 } from 'features/services/archive-7.svg'
import { ReactComponent as FaceRec } from 'features/services/face_rec.svg'
import useHover from 'hooks/useHover'
import { useDispatch, useSelector } from 'react-redux'
import { betaTestInProduction, copyToClipboard } from 'utils/helpers'
import { showInfoMessage } from 'store/ui/actions'
import { layerGroup } from 'leaflet'
import i18next from 'i18next'
import { ShutdownFeature } from 'shared/lib/ShutdownFeature'
import { ReactComponent as EditOutline } from '../../../assets/EditOutline.svg'

const Tag = styled.span`
  background: ${(p) => {
    switch (p.color) {
      case 'error':
        return p.theme.palette.error[50]

      default:
        return p.theme.palette.primary[500]
    }
  }};
  color: ${(p) => p.theme.palette[p.color].contrastText};
  padding: 0.125rem 0.325rem;
  border-radius: 8px;
  display: inline-flex;
`

const OnlineIcon = styled(Power)`
  color: ${(p) => p.theme.palette.success[500]};
  font-size: 0.825rem;
  margin-left: 0.325rem;
`
const OfflineIcon = styled(PowerOff)`
  color: ${(p) => p.theme.palette.error[500]};
  font-size: 0.825rem;
  margin-left: 0.325rem;
`

const styles = {
  icon: { fontSize: '0.625rem', marginRight: '.325rem', width: 16, height: 16 },
}

const serviceIcons = {
  archive_3: Archive3,
  archive_5: Archive5,
  archive_7: Archive7,
  face_rec: FaceRec,
}

const isThirdPartyCamera = (serial_number) =>
  serial_number?.slice(0, 2) === 'DC'

export default function DeviceSearchSuggestion({
  firstInstalled,
  secondaryAction,
  to,
  type,
  withAddress = true,
  installed,
  uuid,
  address_type,
  address_uuid,
  serial_number,
  short_address,
  is_online,
  services,
  handleOpenModal,
  alias,
  ...props
}) {
  const { t } = useTranslation(['devices', 'info'])
  const { hovered, bind } = useHover()
  const dispatch = useDispatch()
  const companyUuid = useSelector(({ auth }) => auth.user?.company?.uuid)

  const serviceText = {
    archive_3: i18next.t('devices:helper_text.archive_day', { days: 3 }),
    archive_5: i18next.t('devices:helper_text.archive_day', { days: 5 }),
    archive_7: i18next.t('devices:helper_text.archive_day', { days: 7 }),
    face_rec: i18next.t('devices:helper_text.face_rec'),
  }

  const handleEditThirdPartyCamera = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      handleOpenModal({ uuid })
    },
    [handleOpenModal, uuid]
  )

  return (
    <ListItem
      style={{ minHeight: '60px' }}
      // disabled={!installed}
      key={uuid}
      component={Link}
      to={to || (address_type === 'gate' ? `/gate/` : '/e/') + uuid}
      button
      {...bind}
      {...props}
    >
      <ShutdownFeature>
        <ListItemIcon>
          {type === 'camera' ? (
            isThirdPartyCamera(serial_number) ? (
              <StrangeCamera style={{ fill: 'black' }} />
            ) : (
              <ISee />
            )
          ) : (
            <SputnikIcon />
          )}
        </ListItemIcon>
      </ShutdownFeature>
      <ListItemText
        primary={
          <>
            <ShutdownFeature>
              {installed && (
                <>
                  <Tooltip
                    title={
                      is_online
                        ? t('devices:params.online')
                        : t('devices:params.offline')
                    }
                  >
                    {is_online ? (
                      <OnlineIcon style={styles.icon} />
                    ) : (
                      <OfflineIcon style={styles.icon} />
                    )}
                  </Tooltip>
                  {/* <Archive style={styles.icon} /> */}
                </>
              )}
              {services?.map((identifier) => {
                const Component = serviceIcons[identifier]
                const text = serviceText[identifier]
                return (
                  <Tooltip title={text} key={identifier}>
                    <Component style={styles.icon} />
                  </Tooltip>
                )
              })}
            </ShutdownFeature>
            <Typography>
              {props.address || t('devices:params.not_installed')}
            </Typography>
            {/* {hovered && (
              <FileCopy
                style={{ width: 14, height: 14, marginLeft: '1rem' }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  copyToClipboard(short_address)
                  dispatch(
                    showInfoMessage(
                      t('info:copy.device', { number: short_address })
                    )
                  )
                }}
              />
            )} */}
          </>
        }
        secondaryTypographyProps={{
          style: {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      />
      <ShutdownFeature>
        <ListItemSecondaryAction>
          {firstInstalled && firstInstalled.uuid === uuid && (
            <Tag color="primary">
              <Typography color="inherit">enter</Typography>
            </Tag>
          )}

          {/* {secondaryAction} */}
          {/* {!installed && (
        <Tag color="error" style={{ marginLeft: 'auto' }}>
          <Typography color="textSecondary">не установлена</Typography>
        </Tag>
      )} */}
        </ListItemSecondaryAction>
      </ShutdownFeature>
    </ListItem>
  )
}
