export default {
  baseUri:
    process.env.REACT_APP_BASEURL ||
    'https://api-control.sputnik.systems/api/managment_company_app/v1',
  redirectUri:
    process.env.REACT_APP_REDIRECTURL ||
    'https://api-control.sputnik.systems/api/managment_company_app',
  authUri:
    process.env.REACT_APP_AUTHURI || 'https://api-control.sputnik.systems',
  socketUrl:
    process.env.REACT_APP_SOCKETURL ||
    'wss://api-control.sputnik.systems/cable',
  ssoUri: process.env.REACT_APP_SSOURL || 'https://sso.sputnik.systems',
  notifyUrl: 'https://notifier.sputnik.systems/api/v1/emails',
  intercomId: 'ControlUserWeb',
  brand: 'Спутник',
  app: 'Управляющая Компания',
}
