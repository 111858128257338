import { createSlice } from '@reduxjs/toolkit'
import { fetchAllAddresses } from '../services/fetchAllAddresses'
import { fetchCamerasByUuid } from '../services/fetchCamerasByUuid'

const initialState = {
  pending: false,
  addresses: undefined,
  list: undefined,
  meta: undefined,
  limit: 6,
  filter: '',
  search: undefined,
  // sortBy: 'created_at',
  page: 1,
}

export const devicesVideos = createSlice({
  name: 'devicesVideos',
  initialState,
  reducers: {
    clear: () => initialState,
    setFilter: (state, { payload }) => {
      state.filter = payload
      state.page = 1
    },
    setSearch: (state, { payload }) => {
      state.search = payload
      state.page = 1
    },
    setLimit: (state, { payload }) => {
      state.limit = payload
      state.page = 1
    },
    setPage: (state, { payload }) => {
      state.page = payload
    },
  },
  extraReducers: {
    [fetchAllAddresses.pending]: (state, { payload }) => {
      state.pending = true
    },

    [fetchAllAddresses.fulfilled]: (state, { payload }) => {
      state.addresses = payload.addresses
      state.meta = payload.meta
    },
    [fetchAllAddresses.rejected]: (state, { payload }) => {
      state.pending = false
    },
    [fetchCamerasByUuid.pending]: (state, { payload }) => {
      state.pending = true
    },
    [fetchCamerasByUuid.fulfilled]: (state, { payload }) => {
      state.list = payload
      state.pending = false
    },
    [fetchCamerasByUuid.rejected]: (state, { payload }) => {
      state.pending = false
    },
  },
})

export const { actions: devicesVideosActions } = devicesVideos
export const { reducer: devicesVideosReducer } = devicesVideos
